import React from 'react'
import Slider from  './Slider';
import { Row, Col } from 'react-bootstrap';
import slideimg1 from '../Assets/Website-Devlopment2.svg'
import slideimg2 from '../Assets/mobileapk.svg';
import slideimg3 from '../Assets/Database-Services.svg';
import slideimg4 from '../Assets/Website-Designing.svg';
import Sdata from '../components/Sdata';


export const Services = () => {
  return (
    <div id='Services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        <Row>
          <Col>
            <Slider  imageSrc={slideimg1} title={Sdata[0].title} title2={Sdata[0].title2} title3={Sdata[0].title3} title4={Sdata[0].title4} subtitle={Sdata[0].discription} subtitle2={Sdata[0].discription2} subtitle3={Sdata[0].discription3} subtitle4={Sdata[0].discription4} />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Slider imageSrc={slideimg2} title={Sdata[1].title} title2={Sdata[1].title2} title3={Sdata[1].title3} title4={Sdata[1].title4} subtitle={Sdata[1].discription} subtitle2={Sdata[1].discription2} subtitle3={Sdata[1].discription3} subtitle4={Sdata[1].discription4} flipped={true} />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Slider imageSrc={slideimg3} title={Sdata[2].title} title2={Sdata[2].title2} title3={Sdata[2].title3} title4={Sdata[2].title4} subtitle={Sdata[2].discription} subtitle2={Sdata[2].discription2} subtitle3={Sdata[2].discription3} subtitle4={Sdata[2].discription4} />
          </Col>
        </Row>
        <Row className='mt-4'><Col>
          <Slider imageSrc={slideimg4} title={Sdata[3].title} title2={Sdata[3].title2} title3={Sdata[3].title3} title4={Sdata[3].title4} subtitle={Sdata[3].discription} subtitle2={Sdata[3].discription2} subtitle3={Sdata[3].discription3} flipped={true} subtitle4={Sdata[3].discription4} />
        </Col>
        </Row>
        </div>
         
      </div>
    </div>
  )
}
