import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import logo from '../../Assets/logo1.png';
import { Nav } from 'react-bootstrap';


const Navbarmenu = () => {

    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
        setisMenu(isMenu === false ? true : false);
        setResponsiveclose(isResponsiveclose === false ? true : false);
    };
    let boxClass = ["main-menu menu-right menuq1"];
    if (isMenu) {
        boxClass.push('menuq2');
    } else {
        boxClass.push('');
    }   

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
    const toggleSubmenu = () => {
        setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };

    let boxClassSubMenu = ["sub__menus"];
    if (isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    } else {
        boxClassSubMenu.push('');
    }
    return (
        <Nav className="navbar navbar-fixed-top" >
            <div className="container" >
                <div className="row" >
                    {/* Add Logo  */}
                    <div className="header__middle__logo" >
                        <NavLink exact activeClassName='is-active' to="/" >
                            <img src={logo} alt="logo" />
                        </NavLink>
                    </div>
                    <div className="header__middle__menus" >
                        <nav className="main-nav " >
                            {/* Responsive Menu Button */}
                            {isResponsiveclose === true ? <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                            </> : <>
                                <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiAlignRight />   </span>
                            </>}
                            
                            <ul className={boxClass.join(' ')} >
                                <li className="menu-item" >
                                    <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}> Home </NavLink>
                                </li>
                                <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="/Company"> Company  </Link>
                                    {/* <ul className={boxClassSubMenu.join(' ')} >
                                        <li> <NavLink onClick={toggleClass} activeClassName='is-active' to={`/About`}> About Us </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Team`}> Leadership Team </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/contact`}> Contact Us</NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Engagement`}> Engagement models</NavLink> </li>
                                    </ul> */}
                                </li>
                                <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="/Services"> Services  </Link>
                                    {/* <ul className={boxClassSubMenu.join(' ')} >
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Webser`}> Web devlopment </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Appser`}>Application Development </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Desser`}>Design Services </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Ecomser`}>E-commerce Development </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Proser`}>Product Development </NavLink> </li>
                                    </ul> */}
                                </li>
                                <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="/Languages"> Technology  </Link>
                                    {/* <ul className={boxClassSubMenu.join(' ')} >
                                        <li> <NavLink onClick={toggleClass} activeClassName='is-active' to={`/testimonials`}> Our Pastwork </NavLink> </li>
                                    </ul> */}
                                </li>
                                <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="/contact"> Contact Us  </Link>
                                    {/* <ul className={boxClassSubMenu.join(' ')} >
                                        <li> <NavLink onClick={toggleClass} activeClassName='is-active' to={`/Online`}>Job Posting </NavLink> </li>
                                        <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Offline`}> Form For Candidate</NavLink> </li>
                                    </ul> */}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </Nav>
    )
}
export default Navbarmenu
