import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Webdev4 from '../Assets/Webdev4.svg'
import Webdev5 from '../Assets/Webdev5.svg'
import Webdev3 from '../Assets/Webdev3.svg'

export const Desser = () => {
    return (
        <div id='Desser'>
            <section className='web_Ser'>
                <Row>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Designing Services</h1>
                                <p> Web design and development deals with the detailed process of planning a website's flow, its visual and content strategy, conceptualizing an identity and ensuring that all the necessary web functionalities have been integrated. The final execution involves the development of a mobile responsive website with a front end aimed at giving a good user experience and a backend for an admin's control over the website's content involving images, copy, product information etc.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev3} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev5} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >UX-UI</h1>
                                <p>The need for a web & UI/UX design arises right from the time a brand decides to go digital. In today's day and age, a business without a website is almost unimaginable. A website may just be informative or be equipped with an E-Commerce functionality and in both cases, web design plays an important role. Once your web design is ready, the development begins.</p>
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >App Designing</h1>
                                <p>App design combines the user interface (UI) and user experience (UX). While UI lends itself to the overall style of the app (including the colors, fonts, and general look and feel), UX focuses on the actual functionality and usability.
                                   A large number of users abandon an app after using it for the first time. Since users are picky about which apps they use and so quick to abandon those they don’t enjoy, it’s essential to invest time and effort in creating a great user experience. The better the design, the better the chance that a user will engage with it and thus keep using it.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev4} />
                            </div>
                        </div>
                    </Container>
                    
                </Row>
            </section>
        </div>
    )
}

