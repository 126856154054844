import React from "react";
import {FaReact,FaAngular,FaApple,FaPhp,FaWordpress,FaShopify,FaSwift,FaBootstrap} from 'react-icons/fa'
import { SiAdobexd,SiCodeigniter,SiCss3 ,SiFlutter,SiAdobephotoshop,SiPython,SiWoo} from 'react-icons/si'
import {AiFillAndroid,AiFillHtml5}from 'react-icons/ai'
import{IoLogoLaravel,}from 'react-icons/io5'
 const Ldata= [
    {
      icon:<FaReact/>,
      name: "React JS",
      technologies: "Web Technologies",
    },
     {
      icon:<SiAdobexd/>,
      name: " Adobe XD",
      technologies: "Designing",
    },
    {
      icon:<FaAngular/>,
      name: "Angular",
      technologies: "Web Technologies",
    },
    {
      icon:<SiCodeigniter/>,
      name: "CodeIgniter",
      technologies: "Open Source",
    },
    {
      icon:<SiCss3/>,
      name: "CSS3",
      technologies: "Web Technologies",
    },
    {
      icon:<SiFlutter/>,
      name: "Flutter",
      technologies: "Mobile Technologies",
    },
    {
      icon:<AiFillAndroid/>,
      name: "Android",
      technologies: "Mobile Technologies",
    },
    {
      icon:<FaApple/>,
      name: "iOS",
      technologies: "Mobile Technologies",
    },
    {
      icon:<AiFillHtml5/>,
      name: "HTML5",
      technologies: "Web Technologies",
    },
  
    {
      icon:<FaPhp/>,
      name: "PHP",
      technologies: "Web Technologies",
    },
    {
      icon:<FaWordpress/>,
      name: "WordPress",
      technologies: "Web Technologies",
    },
    {
      icon:<IoLogoLaravel/>,
      name: "Laravel",
      technologies: "Web Technologies",
    },

    {
      icon:<FaBootstrap/>,
      name: "Bootstrap",
      technologies: "Designing",
    },
 
    {
      icon:<SiPython/>,
      name: "Python",
      technologies: "Web Technologies",
    },
    {
      icon:<FaShopify/>,
      name: "Shopify",
      technologies: "E-Commerce Platform",
    },

    {
      icon:<FaSwift/>,
      name: "Swift",
      technologies: "Mobile Technologies",
    },
    {
      icon:<SiAdobephotoshop/>,
      name: "Photoshop",
      technologies: "Designing",
    },
    {
      icon:<SiWoo/>,
      name: "Woo.",
      technologies: "E-Commerce Platform",
    },
 
];
 export default Ldata;