import React from "react";
import { Image } from "react-bootstrap";
export const Companey = (props) => {
  return (
    <div id="Company">
      <div className="container ABT_P">
        <div className="row">
          <div className="col-xs-12 col-md-6 IMG_CON">
            
            <Image src="img/AboutUS_Img.svg" className="img-responsive" alt="" fluid/>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <h3>{props.data ? props.data.paragraph1 : "loading..."}</h3>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
