const Sdata = [
    {
     title:"Website Development",
     discription:"We provide the best web development and web designing services around the globe. Our business is all about you and your business, we make your business better with the website and web designing services. We provide an identity to your business.",
     title2:"• Web Development",
     discription2:"We are a web development company providing IT Solutions to companies worldwide. Your website is the brand figurehead, thus it is very important that we provide you with the best web solutions.",
     title3:"• Wordpress Development",
     discription3:"To provide you with a website close to the entity requirements and the new technology, We create the websites with Word press to keep the website simple.",
     title4:"•	PHP MySQL",
     discription4:"We provide you with the PHP development service, it is a scripting language that will help us provide you with dynamic website development. It is generally used for server side scripting, Which can be further used as a command-line interface."
    },
    {
     title:"Mobile Application Development",
     discription:"We provide mobile application development that helps in building next-gen mobile applications and assist the user with exceptional digital maturity and presence. We at ambitminds along with mobile application development, provide 360-degree service with the update, migration, and maintenance services. ",
     title2:"•	iOS Native",
     discription2:"iOS native means an application and program that run on a specific platform or specific device. In absence of a browser to run the mobile applications, One needs to have a specialized program to run on different operating systems. The codes need to be written in swift or objective-C.",
     title3:"•	Android Native",
     discription3:"Android native applications are developed based on the java language. In comparison to non-native applications, Android native apps are best when it comes to user experience and responsiveness. We design user-friendly applications that can be used offline and accessible anytime anywhere. ",
     title4:"•	Hybrid ",
     discription4:"Hybrid applications are molded in the native application shell. It can be accessed on a browser and can be installed on a mobile instrument with the help of the internet. "
    },
    {
     title:"Product Development",
     discription:"We provide customized software and web designing as per the needs of the business as it is very important to understand the needs of the business along with the customer behavior. By this, The business will be able to achieve the highest number of customers through an online website and branding. ",
     title2:"• ERP Software Development",
     discription2:"We provide the best ERP software development solutions for your fast-growing business. With our most trusted, secured, and scalable ERP software provided by us, your business will have easy-to-use ERP software with financial, sales, CRM, analytics, inventory management, and many more solutions.",
     title3:"• E-Commerce Development Services",
     discription3:"We provide the development of an E-commerce platform on several platforms such as Shopify, Magneto, Woo Commerce, and many more fulfilling the ultimate goal of the business by communicating with the audience. Along with the E-commerce development and IT technology experts, we try to provide you with the best customer satisfaction and make the brand worldwide.",
    },
    {
     title:"Designing Services",
     discription:"We provide web designing with a team of the best web designers and web developers in India, We ensure that the website can support your business to grow more rapidly through the support of web development and good web design.",
     title2:"•	UI/UX Development",
     discription2:"It has become important to build the website with the latest technologies, we provide the best UX designing solutions to our clients, which will bring the finest impressions about the business.",
     title3:"•	Responsive Web Designing",
     discription3:"We provide the best responsive web designing services, it provides the solutions to make the website responsive to the devices and to adjust according to it which will make the website more usable and by which more audience can be achieved.",
     title4:"",
     discription4:" "
    }
 ]
  
 export default Sdata;