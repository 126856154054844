import React from "react";
function TechnologyContainer(props) {
  let {
    data: { technologies },
    index,
    technologySelected,
  } = props;
  return (
    <>
      <h3
        onClick={() => {
          technologySelected({ technologies: technologies });
        }}
        key={index}
      >
        {technologies}
      </h3>
    </>
  );
}

export default TechnologyContainer;
