import React, { useEffect, useState } from 'react';
import Ldata from './Ldata';
import IconContainer from "../components/IconContainer";
import TechnologyContainer from "../components/TechnologyContainer";
import { Col, Row } from 'react-bootstrap';

export const Languages = () => {
  const [technology, setTechnology] = useState([]);
  const [technologyIcons, setTechnologyIcons] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState([]);

  const getData = () => {
    const uniqueTechnologies = new Set();
    const uniqueTechnologiesIcons = new Set();

    const unique = Ldata.filter((item) => {
      const isDuplicate = uniqueTechnologies.has(item.technologies);
      uniqueTechnologies.add(item.technologies);
      if (!isDuplicate) {
        return true;
      }
      return false;
    });

    const uniqueIcons = Ldata.filter((item) => {
      const isDuplicate = uniqueTechnologiesIcons.has(item.name);
      uniqueTechnologiesIcons.add(item.name);
      if (!isDuplicate) {
        return true;
      }
      return false;
    });
    //console.log(unique);
    setTechnology(unique);
    setTechnologyIcons(uniqueIcons);
  };

  useEffect(() => {
    let isEffect = true;
    if (isEffect) {
      getData();
    }
    return () => {
      isEffect = false;
    };
  }, []);

  const technologySelected = (technologies) => {
    setSelectedTechnology(technologies);
  };
  return (
    <div className="Technology L__BG" id='Technology'>
      <header className="section-title_L">
        <h2 className="language__title">We Work On</h2>
      </header>
      <Row className="container__hero">
        <Col md={12} lg={6} className="container__heroLeft">
          {technology.map((data, index) => {
            return (
              <TechnologyContainer
                data={data}
                index={index}
                technologySelected={technologySelected}
                key={index}
              />
            );
          })}
        </Col>
        <Col md={12} lg={6} className="cards">
          {technologyIcons.map((item, index) => {
            return (
              <IconContainer
                data={item}
                index={index}
                selectedTechnology={selectedTechnology}
                key={index}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
}

