import React from 'react'
function IconContainer(props) {
    let {data:{icon, name,technologies},index,selectedTechnology} = props;
  return (
    <div className={technologies === selectedTechnology.technologies?'card-active':'card'} key={index}>
                <div className="lanuages__icons">
                  {/* <img
                    src={image}
                    alt=""
                    style={{ height: "80px", width: "80px" }}
                  /> */}
                  <i>{icon}</i>
                </div>
               
                <div>
                  <h4 className='discription' >{name}</h4>             
                </div>  
              </div>
  )
}

export default IconContainer