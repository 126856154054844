import React,{useState, useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import AboutIMG from '../Assets/contact.svg'
import vison from '../Assets/contact.svg'
import { Contact } from './contact'
import JsonData from "../data/data.json"

export const About = (props) => {
  const [AmbitmindsData, setAmbitmindsData] = useState({});
  useEffect(() => {
    setAmbitmindsData(JsonData);
  }, []);
  return (
    <>
      <section className='wraper' id='About'>
           <div id='stars'></div>
           <div id='stars2'></div>
           <div id='stars3'></div>
        <Container>
          <Row className='align-items-center'>
            <Col xs={12} md={6} xl={7}>
              <span className='tagline'>About Us</span>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <button onClick={() => console.log("connect")}>Let's Connect<ArrowRightCircle size={25} /></button>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <img src={AboutIMG} alt='Aboutimg' fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <div id='services' className='text-center'>
        <div className='Container'>
          <div className='section-title'>
            <h2>Our Services</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p>
          </div>
          <Row>
            {
              props.data ? props.data.Services.map((d,i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
                : 'loading'}
          </Row>
        </div>
      </div>
      <section className='V_section'>
        <Row >
          <Col md={2} xs={12} className="V_title">
            <div className='Vision'>Our Visions</div>
          </Col>
          <Col md={10} xs={12}>
            <div className='V_discription'>
              <div className='V_image'>
                <img src={vison} alt="Vision" fluid />
              </div>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove</p>
            </div>
          </Col>
        </Row>
      </section>
      <section className='V_section'>
        <Row className='w-100 flex-lg-row' >
          <Col md={10} xs={12}>
            <div className='V_discription' >
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove</p>
              <div className='V_image'>
                <img src={vison} alt="Vision" fluid />
              </div>
            </div>
          </Col>
          <Col md={2} xs={12} className="V_title">
            <div className='Vision2'>Our mission</div>
          </Col>
        </Row>
      </section>
      <section className='V_section'>
        <Row className='w-100 flex-lg-row'>
          <Col md={2} xs={12} className="V_title">
            <div className='Vision'>Our Visions</div>
          </Col>
          <Col md={10} xs={12}>
            <div className='V_discription'>
              <div className='V_image'>
                <img src={vison} alt="Vision" fluid />
              </div>
              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncove</p>
            </div>
          </Col>
        </Row>
      </section>
        <Contact data={AmbitmindsData.Contact}/>
    </>
  )
}


