import React from 'react'
import { useInView } from 'react-intersection-observer';
import Image  from 'react-bootstrap/Image';




    const Slider = ({ imageSrc, title,title2,title3,title4, subtitle,subtitle2,subtitle3,subtitle4, flipped, }) => {
        const { ref, inView} = useInView({
            /* Optional options */
            threshold: 0.4,
          }); 
           const rendercontent = () =>{
           
             if(!flipped){
                return(<>
                <div className='illusion__img'>
                  <Image src={imageSrc} alt="img update soon " fluid/>
                  </div>
                   <div className='slider_content'>
                    <div className='slider__title'>
                    <h1 >{title}</h1>
                    </div>
                    <div className='liner'/>
                    <p>{subtitle}</p>
                    <div className='slider__title2'>
                    <h1>{title2}</h1> 
                    </div>
                    <p className='sub_title2'>{subtitle2}</p>
                    <div className='slider__title2'>                
                    <h1>{title3}</h1> 
                    </div>
                    <p className='sub_title2'>{subtitle3}</p>
                    <div className='slider__title2'>                
                    <h1>{title4}</h1> 
                    </div>
                    <p className='sub_title2'>{subtitle4}</p>
                    </div>
                  
                </> );
               }else{
                 return(
                 <>
                  
                   <div className='slider_content'>
                    <div className='slider__title'>
                    <h1 >{title}</h1>
                    </div>
                    <div className='liner'/>
                    <p>{subtitle}</p>
                    <div className='slider__title2'>
                    <h1>{title2}</h1> 
                    </div>
                    <p className='sub_title2'>{subtitle2}</p>
                    <div className='slider__title2'>                
                    <h1>{title3}</h1> 
                    </div>
                    <p className='sub_title2'>{subtitle3}</p>
                    <div className='slider__title2'>
                    <h1 >{title4}</h1>
                    </div>
                    <p className='sub_title2'>{subtitle4}</p>
                    </div>
                  <div className='illusion__img'>
                 <Image src={imageSrc} alt="img update soon " fluid/>
                </div>
                 
                </>);
            }
           };
        
          return (<div className={inView ? "slider slider--zoom" : "slider"} ref={ref}>{rendercontent()}</div>
          )
        }
        

    export default Slider;