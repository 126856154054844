import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Fixed from '../Assets/Fix-Price.svg'
import High_brid from '../Assets/high-brid.svg'
import Hourly from '../Assets/Hourlymodel.svg'


const Engagement = () => {
    return (
        <>
            <section className='engagement' >
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div className='Eng_title'>
                    <h1>Our engagement models</h1>
                    <p>There are many variations of passages of Lorem Ipsum available,</p>
                </div>
            </section>
            <section className='Eng_price'>
                <Row>
                    <Container className='Eng_container'>
                        <div className='col-md-8'>
                            <div className='Eng_CON'>
                                <h1 >Fixed Price Model</h1>
                                <p> The fixed price model is for those projects which have a clear scope that is defined. This is a risk-free model for the client as well as the service provider because the timeline and the total cost are fixed right at the start. The deliverables are also clearly defined in this case.

                                    So if your requirements are specific then our fixed price models are apt for you.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Fixed} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Eng_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Hourly} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='Eng_CON'>
                                <h1 >Hourly Price Model</h1>
                                <p>
                                    The hourly price model is best suited for those projects that do not have a well-defined requirement or scope. These are projects whose implementation plans are assumed to change during the development stages. This is a pay per use model where the project cost is dependent on the resources that are utilized and the time that is spent. The payment for this is done hourly.

                                    This model offers flexibility to the clients so that they can manage their project specifications as and when required. The hourly model is apt for projects that are complicated and can have numerous changes.

                                </p>
                            </div>
                        </div>
                    </Container>
                    <Container className='Eng_container'>
                        <div className='col-md-8'>
                            <div className='Eng_CON'>
                                <h1 >Hybrid Models</h1>
                                <p>The hybrid models are models that provide offshore developments and manage the project locally. The clients can benefit from local project management in their country and the developments are offshored. This helps to eliminate any communication gap because of the time zone difference. Because the development is done offshore the model is cost-effective. The model also offers greater operational benefits.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={High_brid} />
                            </div>
                        </div>
                    </Container>
                    {/* <Container className='Eng_container'>
                        <div className='col-md-4'>
                            <img height="100%" src={Hourly} />
                        </div>
                        <div className='col-md-8'>
                            <div className='Eng_CON'>
                                <h1 >Fixed Price Model</h1>
                                <p>Browse in store or online to bring a tremendous table into your space
                                    Browse in store or online to bring a tremendous table into your space
                                    Browse in store or online to bring a tremendous table into your space
                                    Browse in store or online to bring a tremendous table into your space</p>
                            </div>
                        </div>
                    </Container> */}
                </Row>
            </section>
        </>
    )
}

export default Engagement
