import React from 'react';
import Typewriter from 'typewriter-effect';



export const Header = () => {
  return (
    <header id='header'>
      <div className='intro' id={Header}>
           <div id='stars'></div>
           <div id='stars2'></div>
           <div id='stars3'></div>
        <div className='overlay'>s
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-xs-12 col-md-offset-2 intro-text'>
          
                <h2>We Are Passionate About <br />
                    <Typewriter
                        options={{
                        
                            strings: ['Technology', 'Designing', 'Development'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </h2>             
               </div>
            </div>
          </div>
        </div>
      </div>
    </header> 
  )
}
