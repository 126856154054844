import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Webdev1 from '../Assets/Webdev1.svg'
import Webdev2 from '../Assets/Webdev2.svg'
import Webdev3 from '../Assets/Webdev3.svg'


export const Webser = () => {
  return (
    <>
    <div id='Webser'>
      <section className='web_Ser'>
                <Row>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Web Devlopment</h1>
                                <p> While web development typically refers to web markup and coding, website development includes all related development tasks, such as client-side scripting, server-side scripting, server and network security configuration, eCommerce development, and content management system (CMS) development.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev1} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev2} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Why web development ?</h1>
                                <p>
                                The Internet isn’t going anywhere. In fact, it’s become a portal and primary method of research, connection, education, and entertainment in the world. As of 2021, there were 4.66 billion global Internet users — more than half the world’s population.

                               Given the rapidly-increasing number of Internet users, it’s no surprise that web development is a rapidly expanding industry. Between now and 2030, the employment of web developers is expected to grow by 13%, much faster than most other technology careers.

                                </p>
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >What is a CMS?</h1>
                                <p>A content management system (CMS) is a web application or a series of programs used to create and manage web content.While not required to build a website, using a CMS makes things easier. It provides the building blocks (like plugins and add-ons) and lets you create the structure with your code. CMSs are often used for e-commerce and blogging, but they’re useful for all types of websites
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Webdev3} />
                            </div>
                        </div>
                    </Container>
                    </Row>
                    </section>
    </div>
    </>
  )
}


