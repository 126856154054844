import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Ecomser1 from '../Assets/Ecomser1.svg'
import Ecomser2 from '../Assets/Ecomser2.svg'
import Ecomser3 from '../Assets/Ecomser3.svg'


export const Ecomser = () => {
  return (
    <div>
     <section className='web_Ser'>
                <Row>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 > E-commerce Devlopment </h1>
                                <p> Creating an ecommerce website is the first step towards giving your business a headstart. You might be an already successful brick and mortar store owner or just a newcomer planning to try your luck, going online is the best decision you can make for your business. 
                                Creating an ecommerce website gives your business the opportunity to be available for customers 24x7, save costs and reach out to buyers beyond any geographical borders. </p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Ecomser1} />
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Ecomser2} />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >  Choose The Right Plan </h1>
                                <p>Check the plans carefully and choose the one that best suits your current business model. Do not worry about growth as we have higher plans which accommodate the requirements of your growing business.Merely creating an ecommerce website is not enough. You need to choose the right design template and tweak it according to your business to engage and convert your customers. Storehippo offers 100+ beautiful design themes along with drag and drop tools to easily customize them. </p>
                            </div>
                        </div>
                    </Container>
                    <Container className='Web_container'>
                        <div className='col-md-8'>
                            <div className='web_CON'>
                                <h1 >Buy A Domain Name</h1>
                                <p>Buying a domain name is the first step towards building your brand identity. Choose a name that is easy to remember and carries the essence of your business.</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Model_img'>
                                <img height="100%" src={Ecomser3} />
                            </div>
                        </div>
                    </Container>
                    
                </Row>
            </section>
      
    </div>
  )
}

 
